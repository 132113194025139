import * as React from 'react';
import { useParams } from 'react-router';

import { BooleanField, DropdownField, FormModel, InputField, LinkField, MenuItem, Panel, OptionsMenu, Section } from 'app2/components';

import { AdminSiteRouteParams } from '../adminRoutes';
import { ClearStripeTokenModal, createAdjustmentHelper, CopyStripeTokenModal } from '../shared';
import { ImportOneRoster } from './ImportOneRoster';

import { adminSiteActivate, adminSiteCreateAdjustment, adminSiteDeactivate, useSingleAdminSiteQuery, SingleAdminSiteSelections, adminUpdateSite, siteClearStripeToken } from './generated';

export function SiteInfo() {
  const { id } = useParams<AdminSiteRouteParams>();
  const [result] = useSingleAdminSiteQuery({variables: {id}});
  const item = result.data?.site || ({} as SingleAdminSiteSelections);

  function render() {
    return <Panel icon='Home' title={`${item.name} (${id})`} initialValues={item} type='toggle' primaryActions={renderActions()} onOk={onSave}>
      <Section label="ID" name="id" readOnly />
      <Section label='Name' name='name' component={InputField} />
      <Section label='Slug' name='slug' component={InputField} readOnly />
      <Section label='Address' name='streetAddress' component={InputField} />
      <Section label='City' name='city' component={InputField} readOnly />
      <Section label='State' name='state' component={InputField} readOnly />
      <Section label='Zip' name='zipcode' component={InputField} />
      <Section label='Timezone' name='timezone' component={InputField} readOnly />
      <Section label='Retired' name='retired' component={BooleanField} />
      <Section label='Searchable' name='searchable' component={BooleanField} />
      <Section label='Partner' name='partner' component={BooleanField} readOnly />
      <Section label='Active' name='active' component={BooleanField} readOnly />
      <Section label="Stripe account" name="stripeAccount" component={LinkField} readOnly to='https://dashboard.stripe.com/connect/accounts/$value' />
      <Section label='Homeroom rate' name='homeroomRate' component={InputField} />
      <Section label='Transaction fee rate' name='txnFeeRate' component={InputField} />
      <Section label='Hubspot ID' name='hubspotId' component={InputField} />
      <Section label='Organization type' name='organizationType' component={DropdownField} options={organizationTypeOptions} />
    </Panel>
  }

  function renderActions() {
    return (
      <OptionsMenu>
        <MenuItem label="Activate" onClick={activate} />
        <MenuItem label="Deactivate" onClick={deactivate} />
        <MenuItem label="Copy Stripe account..." onClick={<CopyStripeTokenModal destination={item} destinationType='Site' />} />
        <MenuItem label="Clear Stripe account..." onClick={<ClearStripeTokenModal entity={item} mutation={siteClearStripeToken} />} />
        <MenuItem label="Create adjustment..." onClick={createAdjustment} />
        <MenuItem label="Import OneRoster..." onClick={<ImportOneRoster site={id} />} />
      </OptionsMenu>
    );
  }

  async function onSave(form:FormModel<typeof item>) {
    const variables = {
      id,
      attributes: {
        name: form.values.name,
        streetAddress: form.values.streetAddress,
        zipcode: form.values.zipcode,
        retired: form.values.retired,
        searchable: form.values.searchable,
        homeroomRate: parseFloat(form.values.homeroomRate as any),
        txnFeeRate: parseFloat(form.values.txnFeeRate as any),
        hubspotId: form.values.hubspotId,
        organizationType: form.values.organizationType
      }
    }

    const [success] = await adminUpdateSite({variables});

    return success;
  }

  async function activate() {
    const [success] = await adminSiteActivate({variables:{site:id}});

    return success;
  }

  async function deactivate() {
    const [success] = await adminSiteDeactivate({variables:{site:id}});

    return success;
  }

  async function createAdjustment() {
    return createAdjustmentHelper(adminSiteCreateAdjustment, {site:id});
  }

  return render();
}

const organizationTypeOptions = [{
  label: 'Public',
  value: 'public'
}, {
  label: 'Private',
  value: 'private'
}];