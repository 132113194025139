import * as React from 'react';

import { DropdownField, Section } from 'app2/components';

import { useSiteWithTrackingParametersQuery } from '../../../../sites'

interface Props {
  site:string;
}

export function TrackingParameterPicker(props:Props) {
  const [result] = useSiteWithTrackingParametersQuery({variables: {site: props.site}});
  const site = result.data?.site;
  const options = React.useMemo(() => site?.trackingParameters?.map(s => ({label: s.name, value: s.value})), [site?.trackingParameters]);

  if (!site?.usingTrackingParameters || !options?.length) {
    return <></>;
  }

  return <Section label='Source' name='trackingParameter' component={DropdownField} options={options} />
}
