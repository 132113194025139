import * as React from 'react'
import { isEqual, cloneDeep } from 'lodash-es';

import { getNextFocusable, mouseState, Point } from '../../dom-utils';
import { UndoManager, UndoEvent } from '../../undo';
import { TableSection } from '../../virtualized';

import { PasteTableCommand, EditCellCommand } from '../commands';
import { DataTable } from "../DataTable";
import { CellEditorRenderer, adjustRectToRendered } from '../renderers';

// the EditorManager creates cell editors as needed.  it
// allows cell changes to pass to the data source directly
// as the user types, so that validation (if present) is
// updated in realtime.  if the user cancels the edit (escape key) the
// EditorManager reverts the change.  if the user commits the
// change (enter key, or moves the focus) then the change is
// added to the undo stack.

// the editor manager creates the editor on double click or typing
// it also creates an editor at the focus cell as
// the focus changes.  however that editor is not allowed to 
// receive the focus until the user takes some action to give it
// focus (such as single click, double click, or typing).  this
// is so that the arrow keys can continue to work on the table
// as well as other key combinations (like select all, copy, paste, delete, etc)

class Props {
  table:DataTable;
  section:TableSection;
  style:React.CSSProperties;
}

class State {
  editableCellPos?:Point;
  blurred?:boolean;
  prevRecord?:any;
  prevWasDirty?:boolean;
  prevTouched?:boolean;
  event?:KeyboardEvent;
}

export class EditorManager extends React.Component<Props, State> {
  state:State = {};
  // this is specifically not using state because state
  // is updated asynchronously and is not reliably updated
  // between various incoming events
  hasEditor:boolean;
  mouseStart:MouseEvent;
  editableCellPos?:Point;
  blurred?:boolean;
  editor:React.RefObject<HTMLElement> = React.createRef();
  prevTableData:DataTable['data'];

  get hasFocusedEditor() {
    return this.hasEditor && !this.blurred
  }

  get hasBlurredEditor() {
    return this.hasEditor && this.blurred
  }

  get table() {
    return this.props.table;
  }

  get selection() {
    return this.table.selection;
  }

  get focus() {
    const focus = this.selection.focus.clone();
    focus.offset(this.table.rowHeaderCount, this.table.colHeaderCount);

    return focus;
  }

  get editorSameAsFocus() {
    return this.selection?.singleCellSelected && this.editableCellPos?.equal(this.focus);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    // there is no official way to know when the datatable is changing its data set/collection
    // since because of react the data is always changing even if one cell changes
    // but we ned to detect this to cancel editing and not try and save a value in the new unrelated
    // collection, so the best we can do is detect via collection length
    const changedData = this.prevTableData?.length !== this.table.data?.length;
    this.prevTableData = this.table.data;

    if (!this.table.editable) {
      this.clearEditor(false);
      return;
    }

    // this catches focus changes due to non-mouse events (key changes for example)
    if (!this.hasEditor || changedData) {
      this.delayedShowBlurredEditor();
      return;
    }

    if (!this.editorSameAsFocus) {
      this.saveValue();
      this.clearEditor();
      return;
    }
  }

  componentWillUnmount(): void {
    this.clearEditor(false);
  }

  render() {
    if (!this.table?.editable) {
      return <div style={this.props.style}>{this.props.children}</div>;
    }

    // we need to do mouse up capture so we can
    // convert from blurred to focused editor before the
    // editor can intercept the event and do anything with it
    return <div onDoubleClick={this.onDoubleClick} onKeyDown={this.onKeyDown} onKeyPress={this.onKeyPress} onFocusCapture={this.onFocus}
      onMouseDownCapture={this.onMouseDown} onMouseUpCapture={this.onMouseUp} style={{height:'100%', width:'100%', ...this.props.style}}>
      {this.renderEditor()}
      {this.props.children}
    </div>
  }

  renderEditor() {
    if (!this.hasEditor) {
      return;
    }

    const table = this.table;
    const editCell = this.editableCellPos;

    const pos = editCell.clone().offset(-table.rowHeaderCount, -table.colHeaderCount);
    const col = table.cols[pos.col];

    // not sure how this happens, but we've seen it in the wild
    if (!col) {
      return;
    }

    const editCellRect = adjustRectToRendered(this.table.getCellCoordinates(editCell));
    const left = (editCellRect.left - this.props.section.coords.left) + 'px';
    const top = (editCellRect.top - this.props.section.coords.top) + 'px';
    const width = editCellRect.width + 'px';
    const height = editCellRect.height + 'px';

    return <CellEditorRenderer ref={this.editor} style={{position: 'absolute', outline: 'none', left, top, width, height, zIndex: this.state.blurred ? 9 : undefined}} 
      table={table} col={col} colPos={pos.col} rowPos={pos.row} data-row={pos.row} data-field={col.label} onBlur={this.onEditorBlur} event={this.state?.event} blurred={this.blurred}
    />
  }

  // prevents blurred editor from getting the focus
  onFocus = (event:React.FocusEvent) => {
    if (!this.hasBlurredEditor) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    this.table.focus();

    this.saveValue(true);
  }

  // if the user mouses down on the blurred editor
  // allow selection to proceed as normal (until the editor
  // is in normal mode and not blurred mode)
  
  onMouseDown = (event:React.MouseEvent<HTMLDivElement>) => {
    if (this.hasFocusedEditor) {
      return;
    }

    this.mouseStart = event.nativeEvent;

    // specifically check if the user clicked on the editor cell
    // and not the editor component because if the editor extends
    // to other cells, if we checked the component, that would
    // cause the selection manager to start selecting those 
    // other cells, which would clear the blurred editor, which would
    // prevent clicking to activate the blurred editor to a focused editor
    
    const cell = this.table.getCellFromEvent(event);

    if (!cell || !this.hasBlurredEditor || !this.editableCellPos?.equal(cell.pos)) {
      return;
    }
    
    this.table.selectionManager.onMouseDown(event, true);

    event.stopPropagation();
    event.preventDefault();
  }

  // if the user mouses up in a blurred editor, convert it
  // to a focused editor, and forward the mouse down and up
  // events (click will automatically get forwarded by the
  // browser)

  onMouseUp = (event:React.MouseEvent<HTMLDivElement>) => {
    if (this.hasFocusedEditor) {
      return;
    }

    if (!this.blurred || !this.isEditorEvent(event)) {
      this.delayedShowBlurredEditor();
      return;
    }

    event.stopPropagation();
    event.preventDefault();
 
    // lets the selection manager clean up
    this.table.selectionManager.onMouseUp(event.nativeEvent);

    this.setEditor(this.editableCellPos, null, false);

    // get the exact component the mouse down and up 
    // should have been dispatched do that we blocked
    const target = document.elementFromPoint(this.mouseStart.pageX, this.mouseStart.pageY);
    target.dispatchEvent(this.mouseStart);
    target.dispatchEvent(new MouseEvent('mouseup', event.nativeEvent));
  }  

  onDoubleClick = (event:React.MouseEvent<HTMLElement>):void => {
    if (!this.table.editable) {
      return;
    }

    if (!this.isTableEvent(event)) {
      return;
    }

    const cell = this.table.getCellFromEvent(event);

    if (!cell || cell.pos.row < this.table.colHeaderCount || cell.pos.col < this.table.rowHeaderCount) {
      return;
    }

    this.table.selection = this.table.selection.clone().moveTo(cell.pos.clone().offset(-this.table.rowHeaderCount, -this.table.colHeaderCount));

    this.setEditor(cell.pos);

    event.preventDefault();
    event.stopPropagation();
  }

  onKeyDown = (event:React.KeyboardEvent<HTMLDivElement>) => {
    if (!this.table.editable) {
      return;
    }

    if (this.hasFocusedEditor) {
      return this.onEditorKeyDown(event);
    }

    if (!this.isTableEvent(event)) {
      return;
    }

    if (event.key == 'Backspace') {
      UndoManager.instance.push(new PasteTableCommand(this.table, [[null]]));
    }
  }

  onKeyPress = (event:React.KeyboardEvent<HTMLDivElement>) => {
    if (!this.table.editable) {
      return;
    }

    if (this.hasFocusedEditor) {
      return;
    }

    if (!this.isTableEvent(event)) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey) {
      return;
    }

    if (event.key !== String.fromCharCode(event.charCode)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const pos = this.table.selection.focus.clone().offset(this.table.rowHeaderCount, this.table.colHeaderCount);

    this.setEditor(pos, event);
  }

  onEditorBlur = (event:React.FocusEvent<HTMLElement>):void => {
    if (!event.relatedTarget) {
      return;
    }

    if (!this.hasFocusedEditor) {
      return;
    }

    // if the thing getting the focus is part of the editor
    // and focus is just moving around within the editor
    // which can happen with a dual combo, just ignore it
    if (this.editor.current.contains(event.relatedTarget as Node)) {
      return;
    }

    this.saveValue();
    this.showBlurredEditor();
  }

  onEditorKeyDown = (event:React.KeyboardEvent<HTMLElement>) => {
    if (!this.hasFocusedEditor) {
      return;
    }

    if ((event.key == 'Enter' && !event.metaKey) || event.key == 'Tab') {
      const nextFocus = getNextFocusable(document.activeElement);
      const nextIsTable = nextFocus == this.table.element;

      if (!nextIsTable) {
        return
      }

      this.saveValue();
      this.clearEditor();

      this.table.selectionManager.selectFromKeyboardEvent(event, false);
    }
    else
    if (event.key == 'Escape') {
      if (this.blurred) {
        return;
      }

      this.restoreValue();
      this.clearEditor();
      event.preventDefault();
    }
  }

  saveValue(saveBlurredEditor?:boolean) {
    const save = this.hasFocusedEditor || (saveBlurredEditor && this.hasBlurredEditor);
    if (!save) {
      return;
    }

    const table = this.table;
    const editPos = this.editableCellPos.clone().offset(-table.rowHeaderCount, -table.colHeaderCount);

    // sometimes this is getting called when edit cell position
    // is invalid, but its not reproduciable, so guard against it
    // https://joinhomeroom.sentry.io/issues/6276814849
    if (editPos.row >= table?.form?.length) {
      return;
    }

    const values = table.form.getItem(editPos.row);

    if (isEqual(this.state.prevRecord, values)) {
      return;
    }

    UndoManager.instance.push(new EditCellCommand(table, new Point(editPos.col, editPos.row), values, this.state.prevRecord, this.state.prevWasDirty), false);
    this.state.prevRecord = cloneDeep(values);
  }

  restoreValue() {
    const editPos = this.editableCellPos.clone().offset(-this.table.rowHeaderCount, -this.table.colHeaderCount);

    this.table.editableForm.reset(editPos.row, {values: this.state.prevRecord, dirty: this.state.prevWasDirty});
  }

  clearEditor(setFocus:boolean = true) {
    if (!this.hasEditor) {
      return;
    }

    UndoManager.instance.off('action', this.onUndoRedo);
    this.hasEditor = false;
    this.editableCellPos = undefined;
    this.setState({editableCellPos: undefined, event: undefined});

    if (setFocus) {
      this.table.focus();
    }
  }

  delayedShowBlurredEditor() {
    setTimeout(() => {
      if (mouseState.primary) {
        return;
      }

      this.showBlurredEditor();
    });
  }

  showBlurredEditor() {
    if (!this.selection.singleCellSelected) {
      return;
    }

    const focus = this.selection.focus.clone();
    focus.offset(this.table.rowHeaderCount, this.table.colHeaderCount);

    if (!this.props.section.factory.contains(focus.y, focus.x)) {
      return;
    }

    this.setEditor(focus, null, true);
  }

  setEditor(editableCellPos:Point, event?:React.KeyboardEvent, blurred = false) {
    const table = this.table;

    if (!table.editable) {
      return;
    }

    const pos = editableCellPos.clone().offset(-table.rowHeaderCount, -table.colHeaderCount);
    const col = this.table.cols[pos.col];
    const info = this.table.getCellInfo(pos.row, pos.col);

    if (col.disabled || info.disabled || col.readOnly) {
      this.clearEditor();
      return;
    }

    if (this.hasEditor && this.editableCellPos?.equal(editableCellPos)) {
      if (this.blurred != blurred) {
        this.blurred = blurred;
        this.setState({blurred, event: event?.nativeEvent});
      }

      return;
    }

    this.clearEditor();

    const prevRecord = cloneDeep(this.table.form.getItem(pos.row));
    const wasDirty = info.form.dirty;
    UndoManager.instance.on('action', this.onUndoRedo);
    this.hasEditor = true;
    this.editableCellPos = editableCellPos;
    this.blurred = blurred;

    this.setState({editableCellPos, prevRecord, prevWasDirty: wasDirty, prevTouched: info.touched, event: event?.nativeEvent, blurred});
  }

  onUndoRedo = (event:UndoEvent) => {
    if (!this.hasFocusedEditor) {
      return;
    }

    event.preventDefault = true;

    this.restoreValue();
    this.clearEditor();
  }

  isTableEvent(event:React.PointerEvent<HTMLElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) {
    return this.table.element?.contains(event.target as HTMLElement);
  }

  isEditorEvent(event:React.PointerEvent<HTMLElement> | React.MouseEvent<HTMLElement>) {
    return this.editor.current?.contains(event.target as HTMLElement);
  }
}