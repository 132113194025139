import * as React from 'react';
import { Location } from 'history';

import { BreadCrumbs, BreadCrumb, Breakpoints, BoxProps, Heading1, HBox, Icon, VBox, MultiContextProvider, Navigation, NavigationProps, Scroller, Subtitle2, TabletOrDesktop, Phone, useBreakpoints, useNavigateToHash, useHistoryListener, useEmbedModeUpdateSize, embedMessageManager, getScrollParent, childrenToString, useSafeState } from 'app2/components';

import { useCurrentUser, useUserPreference } from '../user';

import { Head, HeadProps } from './Head';
import { Header, HeaderProps, headerZindex } from './Header';
import { Footer } from './Footer';
import { PageContent, pagePadding } from './PageContent';
import { useCartNotifications } from './useCartNotifications';
import { useHackOldUiScrollFix } from './useHackOldUiScrollFix';
import { useRouteInfo } from './useRouteInfo';
import { PageContext } from './PageContext';
import { BackLink } from './BackLink';

// the content prop indicates if you want to use the
// standard min/max page size, page margins and scrolling behavior.
// you generally should always use it.  
//
// using that option ensures that the scrollbars are positioned
// outside the page margins.
//
// you only don't want to use it if you need page elements that
// need to go outside the standard margins (such as the market
// area page header does).  in that case, specify content
// false, and then its up to you to handle min/max page size
// margins and scrolling.  you can get some of that behavior
// by using the PageContents component for when you want
// the standard min/max page size.  however you can not get
// the specialize scrollbar layout that the content true option gets.

export interface PageProps extends Omit<BoxProps, 'px'> {
  // for page metadata
  head?:HeadProps;  
  // if you specify header = true will just show header
  // if you specify a react node, it will add that node as primary actions
  // if you specify header properties, it will apply those properties
  header?: boolean | React.ReactNode | HeaderProps;
  footer?: boolean;
  navigation?: React.ReactElement<NavigationProps, typeof Navigation>;
  back?:React.ReactNode;
  // if you are using max width this is content that will be placed at
  // the top and bottom outside of the full width area
  above?:React.ReactNode;
  below?:React.ReactNode;
  crumbs?:BreadCrumb[];
  px?:number | number[];
  useMaxWidth?:boolean;
  title?: string;
  subtitle?:React.ReactNode;
  status?:React.ReactNode;
  actions?:React.ReactNode;
  scrollArea?:React.RefObject<HTMLElement>;
  // defaults to true, to using a custom scroller that puts margins
  // inside the scrollbars (which html doesn't normally do)
  // turning this off, implicitly turns off the footer
  scroller?:boolean;
}

export function Page(props: PageProps) {
  const { fetching } = useCurrentUser();

  const scrollArea = React.useRef<HTMLElement>();
  const { head, header, footer, navigation, back:propsBack, above, below, crumbs, title, subtitle, status, actions, children, maxWidth, useMaxWidth, pb, hAlign, scroller, ...remainingProps } = props;
  const breakpoint = useBreakpoints();

  // we need to two ways of dealing with the navigation closer
  // for desktop we want allowing to close it and make it persistent - and default to open
  // but on mobile there's no value to persisting it since it covers everything - and default to closed
  const navState = React.useState(true)
  const navPref = useUserPreference('navigationClosed')
  const [ navigationClosed, setNavigationClosed ] = breakpoint == 2 ? navPref : navState;

  useNavigateToHash(scrollArea.current);
  useHistoryListener(onHistoryNavigate, false);
  useCartNotifications();
  useHackOldUiScrollFix();

  const embedded = useEmbedModeUpdateSize(scrollArea);

  const { route } = useRouteInfo();

  const [scrollable, setScrollable] = useSafeState(true);

  function render() {
    let headerProps:Partial<HeaderProps> = typeof header == 'object' 
      ? "primaryActions" in (header as HeaderProps)
        ? header as HeaderProps
        : {primaryActions: header}
      : undefined;

    if (route.props?.header) {
      headerProps ||= {};
      headerProps.primaryActions = <>{headerProps.primaryActions}{route.props.header}</>;
    }

    return <>
      {head && <Head {...head} />}
      <VBox height="var(--app-height, 100vh)" width="100%" overflow="hidden">
        {header && <Header onClickHamburger={navigation && onClickHamburger} {...headerProps} />}
        <HBox height="100%" width="100%" overflow="hidden">
          {renderNavigation()}
          {renderContent()}
        </HBox>
      </VBox>
    </>
  }

  function renderNavigation() {
    if (!navigation) {
      return;
    }

    const popup = breakpoint != Breakpoints.desktop;

    if (navigationClosed) {
      return !popup && renderNavigationOpen();
    }

    return React.cloneElement(navigation, {popup, onClickClose: onClickCloseNavigation, zIndex: popup ? headerZindex + 1 : undefined});
  }

  function renderNavigationOpen() {
    return <HBox bg='primary' vAlign='center'><Icon name='Right' color='white' onClick={() => onClickHamburger()} /></HBox>
  }

  function renderTitle() {
    const hasTitle = title || status || actions || subtitle;

    if (!hasTitle) {
      return;
    }

    return <>
    <TabletOrDesktop>
      <VBox mb='$30' data-test={`Title(${childrenToString(title)})`}>
        <HBox width="100%" vAlign="top" hAlign='baseline' >
          {title && <Heading1>{title}</Heading1>}
          {status && <HBox height='38px' mt='5px' mb='auto' ml='$30' vAlign='center'>{status}</HBox>}
          {actions && <HBox height='max(38px, min-content)' mt='5px' mb='auto' vAlign='center' hAlign='right' flex={1}>{actions}</HBox>}
        </HBox>
        {subtitle && <Subtitle2 mt='$4'>{subtitle}</Subtitle2>}
      </VBox>
    </TabletOrDesktop>
    <Phone>
      <VBox mb='$20' data-test={`Title(${childrenToString(title)})`}>
        <HBox width="100%" vAlign="baseline" gap='20px' flexWrap='wrap'>
          <Heading1 flex={1} maxLines={2} minWidth='150px'>{title}</Heading1>
          {status && <HBox>{status}</HBox>}
          {actions}
        </HBox>
        {subtitle && <Subtitle2 mt='$4'>{subtitle}</Subtitle2>}
      </VBox>
    </Phone>
    </>
  }

  function renderContent() {
    const props = useMaxWidth ? {pt:['$8', '$30']} : {pt:['$8', '$30'], px: pagePadding};

    return <MultiContextProvider<PageContext> page={{scrollable, setScrollable}}>
      {scroller 
        ? <Scroller scrollArea={scrollArea} data-test='Page' width='100%' {...props} pb={embedded ? '' : scrollable ? pb : undefined} scrollbars={!embedded} overflow={scrollable === false ? 'hidden' : undefined} {...remainingProps}>{renderChildrenAndFooter()}</Scroller>
        : children
      }
    </MultiContextProvider>
  }

  function renderChildrenAndFooter() {
    return <>
      {crumbs && <PageContent><BreadCrumbs crumbs={crumbs} mb="$30" /></PageContent>}
      {above}
      {!useMaxWidth
        ? <>{renderChildren()}</>
        : <PageContent maxWidth={maxWidth}>{renderChildren()}</PageContent>}
      {below}
      {footer && !fetching && !embedded && <Footer />}
    </>
  }

  function renderChildren() {
    const back = typeof propsBack === 'string'? <BackLink>{propsBack}</BackLink> : propsBack;

    return <VBox position='sticky' left={0} overflow={route?.props?.scrollable === false || scrollable === false ? 'hidden' : undefined} hAlign={hAlign}>
      {back}{renderTitle()}{children}
    </VBox>
  }

  function onClickHamburger() {
    setNavigationClosed(!navigationClosed);
  }

  function onClickCloseNavigation() {
    setNavigationClosed(true);
  }

  // on any navigate we want to the horizontal scrollbar to reset
  // we don't reset the vertical scroll on same page navigages
  // (such as navigating to another tab on the page), but if its
  // a different page then the apps ScrollTo will reset the vertical scroll
  // specifically, we don't use scrollTo, which will communicate with a parent
  // frame when in embed mode, because in embed mode we don;t know our true
  // current scroll top (it will read as 0 to us), so we don't want to change that
  function onHistoryNavigate(location: Location, _:any, prevLocation: Location) {
    const scroller = getScrollParent(scrollArea.current, true);

    if (!scroller) {
      return;
    }

    scroller.scrollTo(0, scroller.scrollTop);
  }

  return render();
}

Page.defaultProps = {
  scroller: true
}

embedMessageManager.prefix = 'homeroom-';
